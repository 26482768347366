.ccontainer {
  .content-grid {
    grid-template-rows: repeat(var(--rows), minmax(0, 1fr));
    transition: all 0.6s ease;
    //--x-gutter: 2rem;
    //--y-gutter: 6rem;
    --x-gutter: var(--gutter);
    --y-gutter: var(--gutter);
    column-gap: var(--x-gutter);
    row-gap: var(--y-gutter);
  }

  .content-flex {
    transition: all 0.6s ease;
  }

  .content-block {
    //--size: calc((100% / var(--rows)) - var(--x-gutter));
    --expanded-x-size: calc((var(--size) * 2) + var(--x-gutter));
    --expanded-y-size: calc((var(--size) * var(--rows)) + var(--y-gutter) * (var(--rows) - 1));
    width: var(--size);
    height: var(--size);
  }

  .content-block.expanded {
    grid-column: span 2 / span 2;
    grid-row: span var(--rows) / span var(--rows);
    width: min(var(--expanded-x-size), 100vw);
    //height: min(var(--expanded-y-size), 100%);
    height: var(--expanded-y-size);
  }

  .filler {
    content: "";
    width: 1rem;
  }

  //.animating .animate-hide-scroll {
  //  overflow-y: hidden;
  //  padding-right: calc(var(--scrollbar-width) - 1px);
  //}

  .collapse-icon {
    top: calc(50%);
    opacity: 0;
  }

  .animating .collapse-icon {
    opacity: 0;
  }

  .expanded .collapse-icon {
    opacity: 1;
  }

}